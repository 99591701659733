import useSWR from 'swr'

import Link from 'next/link'

import SoundBars from '../animations/SoundBars'
import SpotifyIcon from '@/icons/SpotifyIcon'

export type NowPlayingSong = {
  album: string
  albumImageUrl: string
  artist: string
  isPlaying: boolean
  songUrl: string
  title: string
}

async function fetcher<JSON = Record<string, unknown>>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, init)
  return res.json()
}

export default function NowPlaying() {
  const { data } = useSWR<NowPlayingSong>('/api/now-playing', fetcher)

  // When a track is playing
  if (data?.songUrl) {
    return (
      <div className="flex space-x-2">
        <SoundBars className="w-6 h-6" />
        <Link
          className="font-medium truncate capsize max-w-max text-gray-1200"
          href={data.songUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.title}
        </Link>
        <span className="mx-2 capsize text-gray-1100">{' - '}</span>
        <p className="truncate text-gray-1100 capsize max-w-max">
          {data?.artist ?? 'Spotify'}
        </p>
      </div>
    )
  }

  return (
    <div className="flex space-x-2">
      <SpotifyIcon className="w-6 h-6 transition cursor-pointer fill-gray-700 hover:fill-gray-900" />
      <p className="font-medium capsize text-gray-1100">Not Playing</p>
    </div>
  )
}
