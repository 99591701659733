'use client'

import * as React from 'react'

import Link from 'next/link'

import Avatar from '@/components/ui/Avatar'
import DarkModeToggle from '@/components/buttons/DarkModeToggle'
import navLinks from '@/data/navLinks'
import useIsScrolled from '@/hooks/useIsScrolled'
import MobileNavigation from './MobileNavigation'
import { LazyMotion, domAnimation } from 'framer-motion'

const Header = () => {
  const { isScrolled } = useIsScrolled()
  const [openMenu, setOpenMenu] = React.useState(false)

  return (
    <LazyMotion strict features={domAnimation}>
      <header
        className={`
        sticky left-0 top-0 z-50 flex h-24 w-full flex-wrap
        ${openMenu ? 'fixed' : 'absolute'}
        ${
          isScrolled
            ? '[@supports(backdrop-filter:blur(0))]:bg-gray-100/85 dark:[@supports(backdrop-filter:blur(0))]:bg-gray-1200/85 bg-transparent shadow-lg backdrop-blur transition duration-100'
            : 'shadow-none'
        }
        hover:bg-gray-100
      `}
      >
        <div
          className={`align-center mx-auto flex w-full max-w-screen-xl items-center justify-between space-x-6`}
        >
          <div className="flex pl-4 my-3 gap-x-8">
            <Avatar />
            {navLinks.map(({ path, label }) => {
              return (
                <Link
                  href={path}
                  key={path}
                  className="items-center hidden gap-x-2 text-gray-1100 hover:text-gray-1200 lg:flex"
                >
                  {label}
                </Link>
              )
            })}
          </div>
          <div className="flex justify-end pr-4 space-x-4 pointer-events-auto">
            <DarkModeToggle />
            <MobileNavigation openMenu={openMenu} setOpenMenu={setOpenMenu} />
          </div>
        </div>
      </header>
    </LazyMotion>
  )
}

export default Header
