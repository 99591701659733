'use client'

import * as React from 'react'

import Link from 'next/link'

import Emoji from '@/components/ui/Emoji'
import NowPlaying from '@/components/stats/NowPlaying'
import contactLinks from '@/data/ContactLinks'

const Footer = () => {
  const helpfulLinks = [
    { link: 'Home', to: '/' },
    { link: 'About', to: '/about' },
    { link: 'Blog', to: '/blog' },
    { link: 'Uses', to: '/uses' },
  ]

  return (
    <div className="max-w-screen-xl px-6 mx-auto">
      <div className="py-8 mt-16 border-t border-gray-700">
        <div className="flex flex-col gap-x-20 gap-y-6 lg:flex-row">
          {/* ABOUT */}
          <div className="flex flex-col w-full gap-y-2 lg:w-3/5">
            <p className="text-lg font-bold">About</p>
            <p className="mb-2 text-sm antialiased w-96 text-gray-1100">
              I&apos;m Yannis Panagis and I&apos;m the Tech Lead at Behaviour
              Lab in London. I spend my time building products, visualizing
              data, exploring London, and writing music.
            </p>
            <NowPlaying />
          </div>

          <div className="flex w-full justify-left gap-x-8 lg:w-2/5 lg:justify-between">
            {/* HELPFUL LINKS */}
            <div className="flex flex-col flex-wrap gap-y-2">
              <p className="text-lg font-bold">Helpful Links</p>
              {helpfulLinks.map(({ to, link }) => (
                <div
                  className="antialiased text-gray-1100 hover:text-gray-1200"
                  key={link}
                >
                  <Link href={to}>{link}</Link>
                </div>
              ))}
            </div>

            {/* CONTACT */}
            <div className="flex flex-col gap-y-2">
              <p className="text-lg font-bold">Contact</p>
              {contactLinks.map(({ text, to }) => (
                <Link
                  className="flex antialiased gap-x-2 text-gray-1100 hover:text-gray-1200"
                  href={to}
                  key={text}
                >
                  {text}
                </Link>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full mt-8 gap-y-2 md:flex-row md:justify-between">
          <p className="antialiased text-gray-1100">
            © 2023 Yannis Panagis. All Rights Reserved.
          </p>
          <p className="antialiased text-gray-1100">
            Powered by Coffee. <Emoji label="Coffee" symbol="☕️" />
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
