const navLinks = [
  {
    path: '/',
    label: 'Home',
    children: [],
  },
  {
    path: '/about',
    label: 'About',
    children: [],
  },
  {
    path: '/blog',
    label: 'Blog',
    children: [],
  },
]

export default navLinks
