import * as React from 'react'

/**
 * Custom hook to determine whether anyone has scrolled on the page
 * @returns {boolean} isScrolled - Whether the page has been scrolled
 */
export const useIsScrolled = () => {
  const [isScrolled, setIsScrolled] = React.useState(false)

  React.useEffect(() => {
    function onScroll() {
      setIsScrolled(window.scrollY > 0)
    }
    onScroll()
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return { isScrolled }
}

export default useIsScrolled
