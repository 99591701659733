import GitHubIcon from '@/icons/GitHubIcon'
import LinkedInIcon from '@/icons/LinkedInIcon'
import TwitterIcon from '@/icons/TwitterIcon'
import SpotifyIcon from '@/icons/SpotifyIcon'

const ContactLinks = [
  {
    text: 'GitHub',
    Icon: GitHubIcon,
    to: 'https://github.com/yp717',
  },
  {
    text: 'LinkedIn',
    Icon: LinkedInIcon,
    to: 'https://www.linkedin.com/in/yannis-panagis/',
  },
  {
    text: 'Twitter',
    Icon: TwitterIcon,
    to: 'https://twitter.com/yannispanagis',
  },
  {
    text: 'Spotify',
    Icon: SpotifyIcon,
    to: 'https://open.spotify.com/artist/4tCn4Wkn4cf0CWLUFvQO58?si=XUyEXMPASyqdhksoCx587g',
  },
]

export default ContactLinks
