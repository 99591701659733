import * as React from 'react'

interface IPropTypes {
  className?: string
}

const SoundBars = ({ className = '' }: IPropTypes) => {
  const ANIMATION_DURATION = '1.2s'
  return (
    <div className={className}>
      <svg
        className="soundbar"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1={3}
          y1={7.5}
          x2={3}
          y2={21}
          stroke="currentColor"
          strokeWidth={4}
        >
          <animate
            attributeName="y1"
            values="7.5;3;7.5;3;7.5"
            dur={ANIMATION_DURATION}
            repeatCount="indefinite"
          />
        </line>
        <line
          x1={10}
          y1={3}
          x2={10}
          y2={21}
          stroke="currentColor"
          strokeWidth={4}
        >
          <animate
            attributeName="y1"
            values="3;12;4.8;7.5;3"
            dur={ANIMATION_DURATION}
            repeatCount="indefinite"
          />
        </line>
        <line
          x1={17}
          y1={7.5}
          x2={17}
          y2={21}
          stroke="currentColor"
          strokeWidth={4}
        >
          <animate
            attributeName="y1"
            values="7.5;4;14;8.5;7.5"
            dur={ANIMATION_DURATION}
            repeatCount="indefinite"
          />
        </line>
      </svg>
    </div>
  )
}

export default SoundBars
