import * as React from 'react'

import XIcon from '@/icons/XIcon'
import BurgerIcon from '@/icons/BurgerIcon'

interface IMenuButtonProps {
  className?: string
  isOpen?: boolean
  onClick?: () => void
}

const MenuButton = ({ isOpen = false, onClick }: IMenuButtonProps) => {
  return (
    // Add an accessible label to this menu button
    <button
      className="flex items-center justify-center fill-gray-1200 text-gray-1200"
      onClick={onClick}
      aria-label="Open mobile menu"
    >
      {isOpen ? (
        <XIcon className="w-6 h-6" />
      ) : (
        <BurgerIcon className="w-6 h-6" />
      )}
    </button>
  )
}

export default MenuButton
