'use client'

import * as React from 'react'

import Link from 'next/link'
import { AnimatePresence, m as motion } from 'framer-motion'

import MenuButton from '@/components/buttons/MenuButton'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import navLinks from '@/data/navLinks'

interface IPropTypes {
  openMenu: boolean
  setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
}

const MobileNavigation = ({ openMenu, setOpenMenu }: IPropTypes) => {
  const ref = React.useRef(null)
  useOnClickOutside(ref, () => setOpenMenu(false))

  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  }

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  }

  return (
    <div className="lg:hidden" ref={ref}>
      {openMenu && (
        <AnimatePresence>
          <motion.div
            className="absolute top-0 left-0 z-20 flex flex-col w-full shadow border-neutral top-24 bg-neutral-50 drop-shadow-2xl dark:bg-neutral-900"
            initial="closed"
            animate="open"
            exit={{ opacity: 0 }}
            variants={sideVariants}
          >
            {navLinks.map(({ label, path }, index) => (
              <motion.div
                className="flex flex-col h-full py-3 mx-6 border-b border-neutral-300 bg-neutral-50 dark:border-neutral-800 dark:bg-neutral-900"
                key={label + index}
                variants={itemVariants}
                transition={{ type: 'spring' }}
                exit={{ opacity: 0 }}
              >
                {/* Force the nav closed when the route change is completed */}
                <Link href={path} passHref onClick={() => setOpenMenu(false)}>
                  <div className="font-semibold select-none text-md hover:text-indigo-1000 text-gray-1200 dark:hover:text-mint-900">
                    {label}
                  </div>
                </Link>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      )}
      <MenuButton
        className="flex justify-center w-5 h-5 mt-1 cursor-pointer text-gray-1200"
        isOpen={openMenu}
        onClick={() => setOpenMenu(!openMenu)}
      />
    </div>
  )
}

export default MobileNavigation
