import * as React from 'react'

import Image from 'next/image'
import Link from 'next/link'

import profilePicture from '../../../public/static/images/profile-picture.png'

interface IAvatarProps {
  className?: string
}

const Avatar = ({ className, ...props }: IAvatarProps) => {
  return (
    <div className="h-14 w-14 animate-border cursor-pointer rounded-full bg-gradient-to-r from-emerald-500 via-indigo-900 to-emerald-500 bg-[length:300%_300%] p-0.5 backdrop-blur transition duration-2000 hover:drop-shadow-lg focus:outline-none focus:ring mr-6">
      <Link
        href="/"
        aria-label="Home"
        className={`${className}`}
        {...props}
        passHref
      >
        <div>
          <Image
            src={profilePicture}
            alt="Yannis Profile Picture"
            className="object-cover rounded-full"
            priority
          />
        </div>
      </Link>
    </div>
  )
}

export default Avatar
